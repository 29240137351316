import React from "react"

import HeaderStyle1 from "../components/Header/HeaderStyle1"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import TransactionGridCol4 from "../components/Portfolio/TransactionGridCol4"
import FooterStyle1 from "../components/Footer/FooterStyle1"
import FeatureStyle4 from "../components/Features/FeatureStyle4"
import buy from "../images/buy.png"
import OfferBuy from "../images/offer-buy.jpg"
import Img1 from "../images/Picture3.png"
import transaction_list from "../components/transaction_list"
import { FaCheckCircle } from "react-icons/fa"
import {Helmet} from "react-helmet"
import Zillow from "../images/zillow2.jpg"
import CallToAction2 from "../components/CallToAction/CallToAction2"

// https://www.vickyhousing.com/sellers/

const Homepage6 = () => {
  return (
    <>
      <HeaderStyle1 />
      <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>湾区买房流程 | 湾区置业专家 湾区Top华人房产经纪 | 熟悉南湾、东湾、三谷市场 | Remax Accord 排名第一经纪人 | 近12个月成交量超1亿美金</title>
              <meta name="description" content="一支秉持诚信、负责、专业的湾区TOP 1% 房产经纪人团队，熟悉南湾、东湾、三谷市场，全面考虑客户利益，帮客户选择最适合的房子，专业团队的一条龙服务给客户带来轻松高效的房产交易体验！团队提供房屋贷款、房屋装修、staging、清洁等服务" />
              <meta name="Keywords" content="湾区,三谷,华人房产经纪,南湾,东湾,湾区买房,湾区买房流程,地产经纪,pleasanton,fremont,cupertino,san jose,mountain view,dublin,palo alto,melo park,livermore,dublin,danville"></meta>
              <meta name="author" content="Jing Xue" />
              <link rel="canonical" href="https://www.jingxuehomes.com" />

              <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "HowTo",
                "name": "旧金山湾区首次买房全攻略",
                "description": "这里相信对很多刚来湾区工作生活的小伙伴们来讲，首次在这里购房可能略微有点无从下手，今天Jing就来跟大家聊聊，首次购房你不得不知道的那点事儿~~",
                "image": {
                  "@type": "ImageObject",
                  "url": "https://www.jingxuehomes.com/static/buy-5fa617297d75a8d2fa95a5a95c80f5c9.png",
                  "height": "406",
                  "width": "634"
                },
                "supply": [{
                    "@type": "HowToSupply",
                    "name": "Pre-Approval Letter"
                  }
                ],
                "tool": [
                  {
                    "@type": "HowToTool",
                    "name": "notched trowel"
                  }, {
                    "@type": "HowToTool",
                    "name": "bucket"
                  },{
                    "@type": "HowToTool",
                    "name": "large sponge"
                  }
                ],
                "step": [
                  {
                    "@type": "HowToStep",
                    "url": "https://www.jingxuehomes.com/buy",
                    "name": "先做 Pre-Approval Letter",
                    "itemListElement": [{
                      "@type": "HowToDirection",
                      "text": "做 Pre-Approval的目的是知道自己能否贷款，以及能贷到多少钱，可以买什么价位的房子。"
                    }],
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://res.cloudinary.com/realtoragents-us/image/upload/v1634104905/preapproval_c8xpmm.jpg",
                      "height": "725",
                      "width": "561"
                    }          
                  }, {
                    "@type": "HowToStep",
                    "name": "确认买房的定位",
                    "url": "https://www.jingxuehomes.com/buy",
                    "itemListElement": [{
                      "@type": "HowToTip",
                      "text": "正式买房之前，会有一次详细的面谈。根据客户的budget和需求，确定合适的买房区域和房型。"
                    }],
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://res.cloudinary.com/realtoragents-us/image/upload/v1634105088/1634105077762_gxm46p.jpg",
                      "height": "796",
                      "width": "1080"
                    }  
                  }, {
                    "@type": "HowToStep",
                    "name": "开始筛选房源",
                    "url": "https://www.jingxuehomes.com/buy",
                    "itemListElement": [{
                      "@type": "HowToTip",
                      "text": "常用的房源软件是Redfin，也有人喜欢用Zillow。为顾客筛选符合需求的房子。"
                    }],
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://res.cloudinary.com/realtoragents-us/image/upload/v1634105207/zillow-redfin_j9mwmg.jpg",
                      "height": "533",
                      "width": "800"
                    }  
                  }, {
                    "@type": "HowToStep",
                    "name": "看Open house",
                    "url": "https://www.jingxuehomes.com/buy",
                    "itemListElement": [{
                      "@type": "HowToTip",
                      "text": "确定顾客感兴趣的房子以后，会陪同一起看房。"
                    }],
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://res.cloudinary.com/realtoragents-us/image/upload/v1634105824/pvufyisfetbyh5qcnfbs.jpg",
                      "height": "194",
                      "width": "259"
                    }  
                  }, {
                    "@type": "HowToStep",
                    "name": "正式下Offer",
                    "url": "https://www.jingxuehomes.com/buy",
                    "itemListElement": [{
                      "@type": "HowToTip",
                      "text": "我们可以拿到这个房子可能会成交的价格，确保客户100%拿到房子，offer命中率达到90%！"
                    }],
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://res.cloudinary.com/realtoragents-us/image/upload/v1634105373/800x800_slplfw.jpg",
                      "height": "338",
                      "width": "450"
                    }  
                  }, {
                    "@type": "HowToStep",
                    "name": "交定金",
                    "url": "https://www.jingxuehomes.com/buy",
                    "itemListElement": [{
                      "@type": "HowToTip",
                      "text": "一旦卖方接受了我们的Offer，房子进入Pending状态，卖家会给买家一定时间Closing，这段时间就是让买家交付定金、贷款、房屋检查等。"
                    }],
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://res.cloudinary.com/realtoragents-us/image/upload/v1634105443/mi0VMS9dmsi9QxXk53oeSK3dVDJh2RxlvpO8P0ut5fKmyCElTI_CeW2aCBl0hU2o0ZalMztQ3WzsKvV874qbdMJrJHqmwJOTPaWk8HRfD0L_DVS5_ravqcj.png",
                      "height": "273",
                      "width": "512"
                    }  
                  }, {
                    "@type": "HowToStep",
                    "name": "联系银行做贷款",
                    "url": "https://www.jingxuehomes.com/buy",
                    "itemListElement": [{
                      "@type": "HowToTip",
                      "text": "可以多找几家银行询问利率，银行之间是可以互相match利率的"
                    }],
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://res.cloudinary.com/realtoragents-us/image/upload/v1634105526/20150827174910270-1_iy1ixl.jpg",
                      "height": "332",
                      "width": "499"
                    }  
                  }, {
                    "@type": "HowToStep",
                    "name": "房屋检查",
                    "url": "https://www.jingxuehomes.com/buy",
                    "itemListElement": [{
                      "@type": "HowToTip",
                      "text": "如果顾客对Seller当时提供的房屋检查报告不放心可以自己花个几百刀请人来做Inspection。"
                    }],
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://res.cloudinary.com/realtoragents-us/image/upload/v1634105676/20160105063058_75310_xb2jqs.gif",
                      "height": "557",
                      "width": "600"
                    }  
                  }, {
                    "@type": "HowToStep",
                    "name": "房屋过户",
                    "url": "https://www.jingxuehomes.com/buy",
                    "itemListElement": [{
                      "@type": "HowToTip",
                      "text": "银行最终决定贷款顺利通过后，会把所有贷款文件邮寄到Escrow。随后Escrow会分别通知买方去签署贷款文件，卖方去签署产权转让。这个签完字后的文件会给寄回给银行，这时候银行会开始放款。Escrow收到银行放款后，会去County登记房子的产权转让。产权转让登记在案后即表示房屋过户完成，我们就可以去领钥匙🔑。"
                    }],
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://res.cloudinary.com/realtoragents-us/image/upload/v1634105718/nl99bnrlwfmuqwwh12fr.jpg",
                      "height": "180",
                      "width": "280"
                    }  
                  }
                ],
                "totalTime": "P2D"
              }
              `}</script>
          </Helmet>
      </div>
      {/* Start Work Flow Section */}
      <section className="pad30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="湾区买房流程" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="template-image mbl-margin-bottom">
                <img className="img-fluid" src={OfferBuy} alt="" />
              </div>
              <div className="template-image mbl-margin-bottom">
                <img className="img-fluid" src={Img1} alt="" />
              </div>
              <div className="template-image mbl-margin-bottom">
                <img className="img-fluid" src={buy} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="working-process-box">
                <FeatureStyle4
                  icon="icon-layers"
                  title="先做 Pre-Approval（贷款预批准）"
                  text="做 Pre-Approval的目的是知道自己能否贷款，以及能贷到多少钱，可以买什么价位的房子。"
                />
                <FeatureStyle4
                  icon="icon-linegraph"
                  title="确认买房的定位"
                  text="正式买房之前，会有一次详细的面谈。根据客户的budget和需求，确定合适的买房区域和房型。"
                />
                <FeatureStyle4
                  icon="icon-target"
                  title="开始筛选房源"
                  text="常用的房源软件是Redfin，也有人喜欢用Zillow。为顾客筛选符合需求的房子。"
                />
                <FeatureStyle4
                  icon="icon-pictures"
                  title="看Open house"
                  text="确定顾客感兴趣的房子以后，会陪同一起看房。"
                />
                <FeatureStyle4
                  icon="icon-wallet"
                  title="正式下Offer"
                  text="我们可以拿到这个房子可能会成交的价格，确保客户100%拿到房子，offer命中率达到90%！"
                />
                <FeatureStyle4
                  icon="icon-envelope"
                  title="交定金"
                  text="一旦卖方接受了我们的Offer，房子进入Pending状态，卖家会给买家一定时间Closing，这段时间就是让买家交付定金、贷款、房屋检查等。"
                />
                <FeatureStyle4
                  icon="icon-lightbulb"
                  title="联系银行做贷款"
                  text="可以多找几家银行询问利率，银行之间是可以互相match利率的"
                />
                <FeatureStyle4
                  icon="icon-magnifying-glass"
                  title="房屋检查"
                  text="如果顾客对Seller当时提供的房屋检查报告不放心可以自己花个几百刀请人来做Inspection。"
                />
                <FeatureStyle4
                  icon="icon-wine"
                  title="房屋过户"
                  text="银行最终决定贷款顺利通过后，会把所有贷款文件邮寄到Escrow。随后Escrow会分别通知买方去签署贷款文件，卖方去签署产权转让。这个签完字后的文件会给寄回给银行，这时候银行会开始放款。Escrow收到银行放款后，会去County登记房子的产权转让。产权转让登记在案后即表示房屋过户完成，我们就可以去领钥匙🔑"
                />
              </div>
              <a href="/how-to-buy-house-in-bay-area" className="btn btn-primary">
                最全湾区买房攻略
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* End Work Flow Section */}

      <section>
          <div className="row">
              <div className="col-md-12">
                  <div className="mb-5">
                      <CallToAction2 bgColor="#222" paddingClass="pad80" text={"我要买房，请联系Jing Xue咨询"}/>
                  </div>
              </div>
          </div>
      </section>
      <section className="pad30" style={{ backgroundColor: "#f3f3f3" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="未上市提前拿下案例" />
            </div>
          </div>
        <div className="row">
          <div className="col-md-6">
                <img src="https://res.cloudinary.com/realtoragents-us/image/upload/v1619940759/Screenshot_from_2021-05-02_00-32-22_nlf4nm.png" alt="" className="w-100" />
                <h4
                    style={{
                    marginTop: "30px",
                    marginBottom: "15px",
                    fontSize: "18px",
                    }}
                >
                    2232 Cameron Cir, Pleasanton, CA
                </h4>
                <ul className="fa-ul">
                    <li>
                        <FaCheckCircle /> 未上市低于市场价10万提前成交: $2,238,000
                    </li>
                </ul>
            </div>
            <div className="col-md-6">
                <img src="https://res.cloudinary.com/realtoragents-us/image/upload/v1619940377/Screenshot_from_2021-05-02_00-25-57_z2ahku.png" alt="" className="w-100" />
                <h4
                    style={{
                    marginTop: "30px",
                    marginBottom: "15px",
                    fontSize: "18px",
                    }}
                >
                    2926 Silva Way
                </h4>
                <ul className="fa-ul">
                    <li>
                    <FaCheckCircle /> 未上市低于市场价避免竞争提前成交: $1,835,000
                    </li>
                </ul>
            </div>
        </div>
        </div>
      </section>
      <section className="pad30" style={{ backgroundColor: "#f3f3f3" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="贷款违约房源 及 Real Estate Owned by the lender（银行屋）" />
            </div>
          </div>
        <div className="row">
          <div className="col-md-6">
                <img src="https://res.cloudinary.com/realtoragents-us/image/upload/v1623135622/noticeofdefault1_cqclmk.jpg" alt="" className="w-100" />
            </div>
            <div className="col-md-6">
                <img src="https://res.cloudinary.com/realtoragents-us/image/upload/v1623135622/noticeofdefault2_ebgmwf.jpg" alt="" className="w-100" />
            </div>
        </div>
        </div>
      </section>
      {/*
      <section className="pad20">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <SectionTitle title="Why Choose Jing" />
                </div>
            </div>
            <div className="row" style={{marginTop: "50px"}}>
                <div className="col-md-12">
                    <p><span className="dropcap">L</span> Whether you’re a first-time buyer, move-up buyer, or investor, Jing’s client-first approach means you can own your dream house faster. For buyers shopping for homes in Silicon Valley, Jing is committed to creating a fun and rewarding home shopping experience for you and your family:</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <p><span className="dropcap bg">P</span>Professional. We have a track record of not only getting things done, but getting things done right the first time. We will treat every aspect of purchasing your future home with competency and expertise.</p>
                </div>
                <div className="col-md-6">
                    <p><span className="dropcap bg">C</span>Communication. We will respond quickly to each of your requests and get you answers to all of the questions you may have and keep you informed with updates related to your interested home.</p>
                </div>
            </div>
            <div className="row" style={{marginTop: "50px"}}>
                <div className="col-md-6">
                    <p><span className="dropcap bg circle">K</span>Knowledge. Deep understanding of the local real estate market to provide you with the information you need to make an informed decision</p>
                </div>
                <div className="col-md-6">
                    <p><span className="dropcap bg rounded">F</span>Full service. We proudly offer full service, meaning we guiding you to purchase a home step by step, from beginning to end.</p>
                </div>
            </div>
        </div>
      </section>
      */}
      <section className="pad-t30 pad-b50">
        <div className="container">
          <div className="row">
              <div className="template-image text-center">
                  <img src={Zillow} alt="" />
              </div>
          </div>
        </div>
      </section>
      {/* Start Portfolio Section*/}
      <section className="pad-t30 pad-b50">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="近期交易" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TransactionGridCol4 transactions={transaction_list}/>
            </div>
          </div>
        </div>
      </section>
      {/* End Portfolio Section*/}

      {/* Start Footer Section*/}
      <FooterStyle1 />
      {/* End Footer Section*/}
    </>
  )
}

export default Homepage6
